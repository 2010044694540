const siteSwitcher = document.querySelectorAll(
    '[data-behaviour="site-switcher"]',
);
const siteOptions = document.querySelector(
    '[data-behaviour="site-switcher-options"]',
);

if (siteSwitcher.length > 0) {
    siteSwitcher.forEach(item => {
        item.addEventListener('click', () => {
            item.classList.toggle('active');
            item.parentElement.classList.toggle('active');
            siteOptions.classList.toggle('active');
        });
    });
}
