var checkListTrigger = document.querySelector(
    '[data-behaviour="gravity-product-trigger"]',
);
var checkList = document.querySelector(
    '[data-behaviour="gravity-product-list"]',
);
var selectTrigger = document.querySelector('.ginput_container_select');

if (checkListTrigger != null) {
    checkListTrigger.addEventListener('click', e => {
        e.preventDefault;
        checkListTrigger.classList.toggle('active');
        if (checkList.classList.contains('visible'))
            checkList.classList.remove('visible');
        else {
            checkList.classList.add('visible');
        }
    });
}

if (checkList != null) {
    checkList.querySelectorAll('.gfield-choice-input').forEach(el => {
        el.addEventListener('change', () => {
            const checked = [...checkList.querySelectorAll('.gfield-choice-input')].filter((el) => el.checked);
            if (checked.length <= 0) {
                checkListTrigger.innerHTML = 'Please select';
                return;
            }

            checkListTrigger.innerHTML = checked.map((el) => el.value).join(', ')
        })
    })
}

if (selectTrigger != null) {
    selectTrigger.addEventListener('click', e => {
        e.preventDefault;
        selectTrigger.classList.toggle('active');
    });
}
