const openSharePanel = document.querySelectorAll(
    '[data-behaviour="open-share-panel"]',
);
const sharePanel = document.querySelector('[data-behaviour="share-panel"]');

openSharePanel.forEach(element => {
    element.addEventListener('click', () => {
        element.classList.toggle('active');
        sharePanel.classList.toggle('share__panel--open');
    });
});
