import React from 'react';
import Utilities from '../../modules/utilities';

const utilities = new Utilities();

export default class BasketPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basketData: {
                basket_info: '',
                basket_url: '',
                checkout_url: '',
                cart_count: 0,
                cart_items: [],
                cart_text: '',
                cart_total: '',
            },
            basketCSS: 'basket-menu popup u-hidden-mobile',
            isDesktop: false,
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }
    componentDidMount() {
        this.setState({ basketData: this.props.basketData });

        window.addEventListener('triggerClick', this.handleRemovePopup);
        window.addEventListener('addedToCart', this.handleUpdateCart);
        window.addEventListener('removed_from_cart', this.handleUpdateCart);

        const { cart_count } = this.props.basketData;
        window.dispatchEvent(
            new CustomEvent('updateMobileBasket', {
                detail: { count: cart_count },
            }),
        );
        this.updatePredicate();
        window.addEventListener('resize', this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 1025 });
    }

    renderItem(item, index) {
        const removeURL = item.remove_url.replace(/&amp;/g, '&');
        const productURL = item.product_url.replace(/&amp;/g, '&');

        return (
            <div className="basket-item" key={index}>
                <a className="basket-item__close" href={removeURL}>
                    <svg
                        id="icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.119"
                        height="14.119"
                        viewBox="0 0 14.119 14.119">
                        <g id="c142_x">
                            <path
                                id="Path_444"
                                data-name="Path 444"
                                d="M7.058,0a7.06,7.06,0,1,0,7.061,7.06A7.059,7.059,0,0,0,7.058,0Zm3.035,8.971L8.969,10.094A25.923,25.923,0,0,0,7.058,8.186a26.292,26.292,0,0,0-1.91,1.908L4.023,8.971a22.032,22.032,0,0,0,1.91-1.909A21.732,21.732,0,0,0,4.023,5.15L5.148,4.025s1.794,1.909,1.91,1.909S8.969,4.025,8.969,4.025L10.094,5.15A25.852,25.852,0,0,0,8.183,7.062C8.183,7.189,10.094,8.971,10.094,8.971Z"
                                fill="#000"
                            />
                        </g>
                    </svg>
                </a>
                <div
                    className="basket-item__image"
                    dangerouslySetInnerHTML={{
                        __html: item.image_markup,
                    }}></div>
                <div className="basket-item__content">
                    <h4 className="product-title">
                        <a href={productURL}>{item.title}</a>
                    </h4>

                    {item.variation && (
                        <span className="product-variation">
                            {item.variation.replace(/-/g, ' ')}
                        </span>
                    )}
                    <span className="product-quantity">
                        Quantity: &nbsp;{item.quantity}
                    </span>
                    <span
                        className="product-price"
                        dangerouslySetInnerHTML={{ __html: item.price }}></span>
                </div>
            </div>
        );
    }

    handleUpdateCart = e => {
        const data = {
            action: 'updateBasketData',
            type: 'AJAX',
        };

        const queryString = utilities.param(data);

        fetch(`${wp_ajax.ajax_url + '?' + queryString}`)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    basketData: JSON.parse(response.data),
                });

                const { cart_count } = this.state.basketData;
                window.dispatchEvent(
                    new CustomEvent('updateMobileBasket', {
                        detail: { count: cart_count },
                    }),
                );

                // Check to see if the popup is already opened
                const { basketCSS } = this.state;
                let split = basketCSS.split(' ');
                let closePopup = true;

                split.filter(item => {
                    if (item === 'popup__menu--active') {
                        closePopup = false;
                    }
                });

                if (e.detail.disablePopup === false && closePopup) {
                    this.handleClick();
                }
            })
            .catch(function(error) {
                console.log('Error during fetch: ' + error.message);
            });
    };

    handleRemovePopup = () => {
        const { basketCSS } = this.state;
        let split = basketCSS.split(' ');
        let result = split.filter(item => item !== 'popup--active');
        this.setState({ basketCSS: result.join(' ') });
    };

    handleClick = e => {
        // Check for click events and prevent default
        if (typeof e !== 'undefined') {
            e.preventDefault();
        }

        const basketCSS = this.state.basketCSS;
        let split = basketCSS.split(' ');
        let result = [];

        if (split.includes('popup--active')) {
            result = split.filter(item => item !== 'popup--active');
        } else {
            split.push('popup--active');
            result = split;
        }

        this.setState({ basketCSS: result.join(' ') });
    };

    render() {
        const {
            basket_info,
            basket_url,
            checkout_url,
            cart_count,
            cart_items,
            cart_text,
            cart_total,
        } = this.state.basketData;

        const items = Object.values(cart_items)
            .map((item, index) => {
                return this.renderItem(item, index);
            })
            .reverse();

        const isDesktop = this.state.isDesktop;

        return (
            <>
                {isDesktop ? (
                    <div
                        className={this.state.basketCSS}
                        data-behaviour="toggle-popup">
                        <div className="popup__menu">
                            <div className="popup__header">
                                <div className="popup__title">
                                    My basket <span>({cart_text})</span>
                                </div>
                                <a
                                    className="popup__close"
                                    data-behaviour="close-popup"
                                    href="#"
                                    onClick={this.handleClick}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="100%"
                                        viewBox="0 0 14.451 14.451">
                                        <g
                                            id="Group_623"
                                            data-name="Group 623"
                                            transform="translate(-1510.161 -757.251)">
                                            <g
                                                id="Group_585"
                                                data-name="Group 585"
                                                transform="translate(1510.161 757.251)">
                                                <path
                                                    id="Path_255"
                                                    data-name="Path 255"
                                                    d="M1523.847,771.7a.763.763,0,0,1-.541-.224l-12.921-12.921a.765.765,0,0,1,1.082-1.082l12.921,12.921a.765.765,0,0,1-.541,1.306Z"
                                                    transform="translate(-1510.161 -757.251)"
                                                    fill="#000"
                                                />
                                            </g>
                                            <g
                                                id="Group_586"
                                                data-name="Group 586"
                                                transform="translate(1510.161 757.251)">
                                                <path
                                                    id="Path_256"
                                                    data-name="Path 256"
                                                    d="M1510.927,771.7a.765.765,0,0,1-.541-1.306l12.921-12.921a.765.765,0,0,1,1.082,1.082l-12.921,12.921A.762.762,0,0,1,1510.927,771.7Z"
                                                    transform="translate(-1510.161 -757.251)"
                                                    fill="#000"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                            <div
                                className={
                                    cart_count >= 2
                                        ? 'basket-menu__items basket-menu__items--will-scroll'
                                        : 'basket-menu__items'
                                }>
                                {items}
                            </div>
                            <div className="basket__sub-total">
                                <div className="sub-total">Subtotal</div>
                                <div
                                    className="price"
                                    dangerouslySetInnerHTML={{
                                        __html: cart_total,
                                    }}></div>
                            </div>
                            <div className="button-row">
                                <a
                                    className="button button__basket button--empty button--no-transform"
                                    href={basket_url}>
                                    View basket
                                </a>
                                <a
                                    className="button button__basket button--dark"
                                    href={checkout_url}>
                                    Checkout
                                </a>
                            </div>
                            {basket_info && (
                                <div
                                    className="basket-info user-content"
                                    dangerouslySetInnerHTML={{
                                        __html: basket_info,
                                    }}></div>
                            )}
                        </div>
                        <div
                            className="basket-menu__basket basket"
                            onClick={this.handleClick}>
                            <div className="basket__count">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19.996"
                                    height="20.002"
                                    viewBox="0 0 19.996 20.002">
                                    <path
                                        id="Union_2"
                                        data-name="Union 2"
                                        d="M16199,21390v-16h4v-4h12v4h4v16Zm1.5-1.5h17.006v-13H16215v3.5h-1.5v-3.5h-9v3.5l-1.5-.006v-3.5h-2.5Zm13-14.5v-2.5h-9v2.5Z"
                                        transform="translate(-16198.999 -21369.998)"
                                    />
                                </svg>
                                {cart_count >= 1 && (
                                    <span className="cart-count">
                                        {cart_count}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="basket-menu basket">
                        <a href={basket_url}>
                            <div className="basket__count">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19.996"
                                    height="20.002"
                                    viewBox="0 0 19.996 20.002">
                                    <path
                                        id="Union_2"
                                        data-name="Union 2"
                                        d="M16199,21390v-16h4v-4h12v4h4v16Zm1.5-1.5h17.006v-13H16215v3.5h-1.5v-3.5h-9v3.5l-1.5-.006v-3.5h-2.5Zm13-14.5v-2.5h-9v2.5Z"
                                        transform="translate(-16198.999 -21369.998)"
                                    />
                                </svg>
                                {cart_count >= 1 && (
                                    <span className="cart-count">
                                        {cart_count}
                                    </span>
                                )}
                            </div>
                        </a>
                    </div>
                )}
            </>
        );
    }
}
