const hideTrigger = document.querySelector([
    '[data-behaviour="hide-remember"]',
]);

const discountBanner = document.querySelector([
    '[data-element="discount-banner"]',
]);

//Hide banner, set cookie
if(hideTrigger && discountBanner) {
    hideTrigger.addEventListener('click', e => {
        e.preventDefault();
        console.log('test');
        discountBanner.dataset.hidden = 'active';
        document.cookie = 'hide_discount_bar=true; SameSite=None; Secure';
    });
}
