import { tns } from 'tiny-slider/src/tiny-slider';

const sliders = document.querySelectorAll('[data-behaviour="slider"]');

if (sliders.length > 0) {
    sliders.forEach(slider => {
        const controls = slider.parentElement.querySelector('.tns-controls');
        // add .carousel-section to carousel twigs with block-colours
        const section_with_colour_blocks = getParentWithClass(
            slider,
            '.section--has-colour-blocks',
        );
        // For timeline
        let indicatorsExist = false;
        var indicators;
        const lowerBreak = 640,
            upperBreak = 1040;

        const navContainer = slider.parentElement.querySelector(
            '.slide-list.nav',
        );

        if (!!navContainer) {
            indicators = navContainer.querySelectorAll(':scope > li');
            if (0 !== indicators.length) {
                indicatorsExist = true;
            }
        }

        let imageSlider = tns({
            container: slider,
            loop: false,
            axis: slider.dataset.direction,
            edgePadding: slider.dataset.padding,
            slideBy: slider.dataset.slideby ? slider.dataset.slideby : 'page',
            center: slider.dataset.center ? slider.dataset.center : false,
            nav: true,
            navPosition: 'bottom',
            navContainer: false,
            autoplay: false,
            speed: 1000,
            lazyload: false,
            autoHeight: false,
            animateDelay: 300,
            disable: false,
            autoplayButtonOutput: false,
            mouseDrag: slider.dataset.mouseDrag
                ? slider.dataset.mouseDrag
                : false,
            touch: slider.dataset.touch
                ? slider.dataset.touch
                : false,
            hasNav: indicatorsExist,
            navContainer: navContainer,
            //navAsThumbnails: ...
            navAsThumbnails: indicatorsExist,
            controlsText: [
                '<svg xmlns="http://www.w3.org/2000/svg" width="10.177" height="16.112" viewBox="0 0 10.177 16.112">\n' +
                    '  <path d="M753.387,779.684l-7,7,7,7" transform="translate(-744.27 -778.623)" />\n' +
                    '</svg>',
                '<svg xmlns="http://www.w3.org/2000/svg" width="10.177" height="16.112" viewBox="0 0 10.177 16.112">\n' +
                    '  <path d="M746.391,779.684l7,7-7,7" transform="translate(-745.331 -778.623)" />\n' +
                    '</svg>\n',
            ],
            controlsPosition: 'bottom',
            controlsContainer: null !== controls ? controls : false,
            responsive: {
                0: {
                    items: slider.dataset.itemsMobile,
                    gutter: slider.dataset.itemsGutter,
                    disable: slider.dataset.disableMobile,
                },
                640: {
                    items: slider.dataset.itemsTablet,
                    gutter: slider.dataset.itemsGutter,
                    disable: slider.dataset.disableTablet,
                },
                1040: {
                    items: slider.dataset.itemsDesktop,
                    gutter: slider.dataset.itemsGutter,
                    disable: slider.dataset.disableDesktop,
                },
            },
            onInit: info => {
                if (null !== controls) {
                    let prev = controls.querySelector('[data-controls="prev"]');
                    if (prev) {
                        prev.setAttribute('type', 'button');
                    }

                    let next = controls.querySelector('[data-controls="next"]');
                    if (next) {
                        next.setAttribute('type', 'button');
                    }

                    firstLastButtonStyling(info, controls);
                    buttonColourOverBlockColourAdjustment(info);

                    controls.querySelector(
                        '[data-controls="next"]',
                    ).onclick = function() {
                        var info = imageSlider.getInfo(),
                            indexCurrent = info.displayIndex;
                        info.slideItems[indexCurrent].classList.add('active');
                        info.slideItems[indexCurrent - 1].classList.remove(
                            'active',
                        );
                    };

                    controls.querySelector(
                        '[data-controls="prev"]',
                    ).onclick = function() {
                        var info = imageSlider.getInfo(),
                            indexCurrent = info.displayIndex;
                        info.slideItems[indexCurrent - 2].classList.add(
                            'active',
                        );
                        info.slideItems[indexCurrent - 1].classList.remove(
                            'active',
                        );
                    };

                    window.onload = function() {
                        var info = imageSlider.getInfo(),
                            indexCurrent = info.displayIndex;
                        info.slideItems[indexCurrent - 1].classList.add(
                            'active',
                        );
                        info.slideItems[indexCurrent - 1].classList.remove(
                            'active',
                        );
                    };
                }

                updateIndicators(info);
            },
        });

        if (imageSlider != null) {
            window.onload = function() {
                var info = imageSlider.getInfo(),
                    indexCurrent = info.displayIndex;
                if (info.index == indexCurrent - 1) {
                    info.slideItems[indexCurrent - 1].classList.add('active');
                }
            };
            imageSlider.events.on('indexChanged', function(info) {
                let count = info.indexCached;
                if (info.index - info.indexCached > 0) {
                    while (count < info.index) {
                        info.slideItems[count].classList.add('slide-up');
                        count++;
                    }
                } else {
                    while (count > info.index) {
                        info.slideItems[count].classList.remove('slide-up');
                        count--;
                    }
                }

                info.slideItems[info.index].classList.remove('slide-up');
                imageSlider.updateSliderHeight();
                firstLastButtonStyling(info, controls);
                buttonColourOverBlockColourAdjustment(info);
                updateIndicators(info);
            });

            imageSlider.events.on('transitionStart', function(info) {
                info.slideItems[info.indexCached].classList.remove('active');

                info.slideItems[info.index].classList.add('active');
            });

            imageSlider.events.on('newBreakpointStart', function(info) {
                imageSlider.updateSliderHeight();
                updateIndicators(info);
            });
        }

        function firstLastButtonStyling(info, controls) {
            if (info.index !== 0 && info.index !== info.slideItems.length - 1) {
                controls.classList.remove('first');
                controls.classList.remove('last');
            }
            if (info.index == 0) {
                controls.classList.add('first');
            }
            if (info.index == info.slideItems.length - 1) {
                controls.classList.add('last');
            }
        }

        function buttonColourOverBlockColourAdjustment(info) {
            let active = info.slideItems[info.index];
            let block_colour_el = active.querySelector('.block-colour');
            if (
                !!section_with_colour_blocks &&
                !!block_colour_el &&
                !!block_colour_el.dataset.blockcolour
            ) {
                let block_colour = block_colour_el.dataset.blockcolour;

                if (!!block_colour_el.dataset.blockcolour) {
                    // check for background-colour of section eg if black then background for
                    section_with_colour_blocks.setAttribute(
                        'currentblockcolour',
                        block_colour,
                    );
                } else {
                    section_with_colour_blocks.setAttribute(
                        'currentblockcolour',
                        '',
                    );
                }
            }
        }

        // For Timeline slider
        function updateIndicators(info) {
            // check if this the timeline slider with indicators or another with indicators
            if (indicatorsExist) {
                indicators[info.indexCached].classList.remove('current');
                indicators[info.index].classList.add('current');
                let offsetInfo = getCurrentElementDimensions(indicators, info);
                let listPosition =
                    offsetInfo['position'] + offsetInfo['size'] / 2;

                navContainer.style.setProperty(
                    '' + offsetInfo['orientation'],
                    'calc(50% - ' + listPosition + 'px)',
                );
            }
        }

        function getElementLocation() {
            let location = [];
            if (window.innerWidth < upperBreak) {
                location['position'] = 'Left';
                location['size'] = 'Width';
            } else {
                location['position'] = 'Top';
                location['size'] = 'Height';
            }
            return location;
        }

        function getCurrentElementDimensions(indicators, info) {
            if (indicatorsExist) {
                let location = [];
                location = getElementLocation();
                let offsetInfo = [];
                offsetInfo['position'] =
                    indicators[info.index]['offset' + location['position']];
                offsetInfo['size'] =
                    indicators[info.index]['offset' + location['size']];
                offsetInfo['orientation'] = location['position'].toLowerCase();
                return offsetInfo;
            }
            return null;
        }

        // classname as '.class'
        function getParentWithClass(el, classname) {
            while ((el = el.parentElement)) {
                if (el.matches(classname)) {
                    return el;
                }
            }
        }
    });
}

// Fix for Safari < 15
if (navigator.userAgent.toLowerCase().indexOf('safari')) [...document.querySelectorAll('.timeline-slider__image img')].map(el => {
    el.setAttribute('src', el.dataset.src);
    el.setAttribute('srcset', el.dataset.srcset);
});