const images = document.querySelectorAll('img.lazy');
const config = {
    rootMargin: '500px',
    threshold: 0.01,
};

let observer;

if ('IntersectionObserver' in window) {
    observer = new IntersectionObserver(onChange, config);
    images.forEach(img => observer.observe(img));
} else {
    images.forEach(image => loadImage(image));
}

const loadImage = image => {
    image.classList.add('fade-in');
    image.datasrc = image.dataset.datasrc;
    image.srcset = image.dataset.srcset;
};

function onChange(changes, observer) {
    changes.forEach(change => {
        if (change.intersectionRatio > 0) {
            // Stop watching and load the image
            loadImage(change.target);
            observer.unobserve(change.target);
        }
    });
}

var oldXHR = window.XMLHttpRequest;

function newXHR() {
    var realXHR = new oldXHR();
    realXHR.addEventListener(
        'readystatechange',
        function() {
            if (realXHR.readyState == 4 && realXHR.status == 200) {
                afterAjaxComplete();
            }
        },
        false,
    );
    return realXHR;
}
window.XMLHttpRequest = newXHR;

function afterAjaxComplete() {
    setTimeout(() => {
        if ('IntersectionObserver' in window) {
            observer = new IntersectionObserver(onChange, config);
            const newImages = document.querySelectorAll(
                '.product-list-item .lazy',
            );
            newImages.forEach(img => observer.observe(img));
        } else {
            newImages.forEach(image => loadImage(image));
        }
    }, 250);
}
