import easydropdown from 'easydropdown';
const tabs = document.querySelectorAll('[data-behaviour="tabs"]');
const dropSelector = document.querySelectorAll(
    '[data-behaviour="dropdown-select"]',
);
const dropDownLinks = document.querySelectorAll(
    '[data-behaviour="dropdown-links"]',
);
const dropSelectorTheme = document.querySelector('[data-theme="dark"]');

if (dropSelector.length > 0) {
    dropSelector.forEach(item => {
        easydropdown(item, {
            behavior: {
                liveUpdates: true,
                maxVisibleItems: 20,
                showPlaceholderWhenOpen: false,
                useNativeUiOnMobile: true,
            },
            classNames: {
                root:
                    null !== dropSelectorTheme
                        ? 'edd-root edd-root--dark'
                        : false,
            },
        });
    });
}

if (dropDownLinks.length > 0) {
    dropDownLinks.forEach(link => {
        easydropdown(link, {
            callbacks: {
                onSelect: value => {
                    let result = document.querySelectorAll(
                        '.tabs__content.active',
                    );

                    for (let i = 0; i < result.length; i++) {
                        result[i].classList.remove('active');
                    }
                    window.location.href = value;
                },
            },

            behavior: {
                liveUpdates: true,
                maxVisibleItems: 10,
                showPlaceholderWhenOpen: true,
                useNativeUiOnMobile: true,
            },
            classNames: {
                root:
                    null !== dropSelectorTheme
                        ? 'edd-root edd-root--dark'
                        : false,
            },
        });
    });
}
