const menuLinks = document.querySelectorAll(
    '[data-behaviour="menu-links-mobile"]',
);

if (menuLinks.length > 0) {
    menuLinks.forEach(item => {
        item.addEventListener('click', e => {
            e.preventDefault;
            item.parentElement.classList.toggle('active');
        });
    });
}
