import './modules/page-load';
import Postcoder from './modules/postcoder';
import '../images';
import '../svgs';
import './modules';
import './components';
import { FontLoader } from './tools';

const fonts = new FontLoader([
    { name: 'NimbusSans-Bold', weight: 700 },
    { name: 'NimbusSans-BoldItalic', weight: 700 },
    { name: 'NimbusSans-Italic', weight: 400 },
    { name: 'NimbusSans-Regular', weight: 400 },
]);

fonts.load();

Postcoder('[data-lookup-address]');