const sortBy = document.querySelectorAll('[data-behaviour="sort-by"]');

const setQueryStringParameter = (name, value) => {
    const params = new URLSearchParams(window.location.search);
    params.set(name, value);
    window.history.replaceState(
        {},
        '',
        decodeURIComponent(`${window.location.pathname}?${params}`),
    );
};

const getParameterByName = (name, url) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

if (sortBy.length > 0) {
    sortBy.forEach(sort => {
        let select = sort.querySelectorAll('select');
        select.forEach(select => {
            select.addEventListener(
                'change',
                function(event) {
                    event.preventDefault
                        ? event.preventDefault()
                        : (event.returnValue = false);

                    let termUrl = this.value;

                    if (termUrl) {
                        let orderBy = getParameterByName('orderby', termUrl);
                        let order = getParameterByName('order', termUrl);

                        if (orderBy) {
                            setQueryStringParameter('orderby', orderBy);
                        }

                        if (order) {
                            setQueryStringParameter('order', order);
                        }

                        let filterButton = document.querySelector(
                            '.js-filter-button button',
                        );

                        if (order || orderBy) {
                            if (filterButton) {
                                filterButton.click();
                            }
                        }
                    }
                },
                { passive: false },
            );
        });
    });
}
