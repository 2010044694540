const toolTips = document.querySelectorAll('.u-tool-tip');
let newElement;
let attribute;

if (toolTips.length > 0) {
    toolTips.forEach(toolTip => {
        attribute =
            '<p class="u-tool-tip__content">This could be similar to 4819H or J200201234 and can be found on the carton, inside the shade or under the base. Read more <a href="https://support.anglepoise.com/hc/en-gb/articles/4419285765265" target="_blank">here</a></p>';
        newElement = document.createElement('span');
        newElement.classList.add('u-tool-tip__icon');
        newElement.innerHTML = attribute;
        toolTip.appendChild(newElement);

        newElement.addEventListener('click', e => {
            const content = toolTip.querySelector('.u-tool-tip__content');
            content.classList.toggle('show');
            toolTip.classList.toggle('u-tool-tip--active');
        });
    });
}
