const openQrPopup = document.querySelectorAll(
    '[data-behaviour="toggle-qr-popup"]',
);
const qrPopup = document.querySelector('[data-behaviour="qr-popup"]');
const closeQrPopup = document.querySelector(
    '[data-behaviour="qr-popup-close"]',
);

openQrPopup.forEach(item => {
    item.addEventListener('click', () => {
        document.body.classList.add('is-scroll-locked');
        qrPopup.classList.toggle('scale-in');
        qrPopup.classList.remove('scale-out');
    });
    closeQrPopup.addEventListener('click', () => {
        document.body.classList.remove('is-scroll-locked');
        qrPopup.classList.toggle('scale-out');
        qrPopup.classList.remove('scale-in');
    });
});
