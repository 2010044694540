import { tns } from 'tiny-slider/src/tiny-slider';

const relatedProducts = document.querySelectorAll(
    '[data-behaviour="related-products"]',
);
const relatedProductColour = document.querySelector(
    '[data-behaviour="related-product-colour"]',
);

const relatedProductBundle = document.querySelector(
    '[data-behaviour="related-product-bundle"]',
);

const maxPurchasableCheck = document.querySelector(
    '[data-behaviour="max-purchasable"]',
);

const sliders = document.querySelectorAll('[data-behaviour="product-slider"]');
let gallery;
let navSlider;
let navSliderMobile;

if (sliders.length > 0) {
    sliders.forEach(slider => {
        navSlider = document.querySelector('[data-behaviour="nav-slider"]');
        const navSliderContainer = document.querySelector(
            '[data-behaviour="nav-slider"]',
        );
        navSliderMobile = document.querySelector(
            '[data-behaviour="nav-slider-mobile"]',
        );
        const navSliderMobileContainer = document.querySelector(
            '[data-behaviour="nav-slider-mobile"]',
        );

        const navContainer = slider.parentElement.querySelector(
            '.slide-list.nav',
        );
        const controls = slider.parentElement.querySelector('.tns-controls');

        // Get slider instance
        gallery = tns({
            container: slider,
            loop: false,
            rewind: true,
            slideBy: 1,
            nav: true,
            center: false,
            lazyload: false,
            navPosition: 'bottom',
            navContainer: null !== navContainer ? navContainer : false,
            autoplay: false,
            speed: 600,
            autoplayButtonOutput: false,
            mouseDrag: true,
            navAsThumbnails: true,
            controlsText: [
                ' <svg id="pagination-arrow-left" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">\n' +
                    '<ellipse id="Ellipse_9" data-name="Ellipse 9" cx="26" cy="26" rx="26" ry="26" transform="translate(0 0)" fill="#fff"/>\n' +
                    '<g id="Group_1322" data-name="Group 1322" transform="translate(23 19.5)">\n' +
                    '<path id="Path_215" data-name="Path 215" d="M0,1.374l6.529,6.5L8,6.505,1.471,0Z" transform="translate(0 0)"/>\n' +
                    '<path id="Path_216" data-name="Path 216" d="M6.573,0,0,6.49,1.412,7.884l6.575-6.49Z" transform="translate(0.008 5.116)"/>\n' +
                    '</g>',
                '</svg>',
            ],
            controlsPosition: 'bottom',
            controlsContainer: null !== controls ? controls : false,
            responsive: {
                0: {
                    items: slider.dataset.itemsMobile,
                    gutter: 10,
                    disable: slider.dataset.disableMobile,
                },
                640: {
                    items: slider.dataset.itemsTablet,
                    gutter: slider.dataset.itemsGutter,
                    disable: slider.dataset.disableTablet,
                },
                1040: {
                    items: slider.dataset.itemsDesktop,
                    gutter: slider.dataset.itemsGutter,
                    disable: slider.dataset.disableDesktop,
                },
            },
            onInit: () => {
                if (null !== controls) {
                    let prev = controls.querySelector('[data-controls="prev"]');
                    if (prev) {
                        prev.setAttribute('type', 'button');
                    }

                    let next = controls.querySelector('[data-controls="next"]');
                    if (next) {
                        next.setAttribute('type', 'button');
                    }
                }
            },
        });

        const navSliderSlides = document.querySelectorAll(
            '[data-behaviour="nav-slider-slide"]',
        );
        navSliderSlides.forEach(slide => {
            slide.onclick = () => {
                gallery.goTo(slide.id.replace(/tns[0-9]-item/, ''));
            };
        });

        if (typeof navSlider !== 'undefined') {
            if (null !== navSlider ? navSlider : false) {
                navSlider = tns({
                    container: navSliderContainer,
                    loop: false,
                    mode: 'carousel',
                    slideBy: 1,
                    center: true,
                    navContainer: false,
                    autoplay: false,
                    speed: 400,
                    autoplayButtonOutput: false,
                    mouseDrag: false,
                    controlsContainer: false,
                    items: 4,
                    axis: 'vertical',
                });
            }
        }

        if (navSlider != null) {
            window.onload = function() {
                var info = navSlider.getInfo(),
                    indexCurrent = info.displayIndex;
                info.slideItems[indexCurrent - 1].classList.add('active');
            };
        }

        if (typeof navSliderMobile !== 'undefined') {
            if (null !== navSliderMobile ? navSliderMobile : false) {
                navSliderMobile = tns({
                    container: navSliderMobileContainer,
                    loop: false,
                    mode: 'carousel',
                    slideBy: 1,
                    center: true,
                    navContainer: false,
                    autoplay: false,
                    speed: 400,
                    autoplayButtonOutput: false,
                    mouseDrag: false,
                    controlsContainer: false,
                    items: 4,
                    axis: 'horizontal',
                });
            }
        }

        if (navSliderMobile != null) {
            window.onload = function() {
                var info = navSliderMobile.getInfo(),
                    indexCurrent = info.displayIndex;
                info.slideItems[indexCurrent - 1].classList.add('active');
            };
        }

        const navSliderFunction = function(evt) {
            if (typeof navSlider !== 'undefined') {
                if (navSlider !== null) {
                    const indexToGoTo =
                        evt.displayIndex > gallery.getInfo().slideCount
                            ? 0
                            : evt.displayIndex - 1;
                    navSlider.goTo(indexToGoTo);
                    var info = navSlider.getInfo();
                    var indexPrev = gallery.getInfo().indexCached;

                    info.slideItems[indexPrev].classList.remove('active');
                    info.slideItems[evt.displayIndex - 1].classList.add(
                        'active',
                    );
                }
            }
            if (typeof navSliderMobile !== 'undefined') {
                if (navSliderMobile !== null) {
                    const indexToGoTo =
                        evt.displayIndex > gallery.getInfo().slideCount
                            ? 0
                            : evt.displayIndex - 1;
                    navSliderMobile.goTo(indexToGoTo);
                    var info = navSliderMobile.getInfo();
                    var indexPrev = gallery.getInfo().indexCached;

                    info.slideItems[indexPrev].classList.remove('active');
                    info.slideItems[evt.displayIndex - 1].classList.add(
                        'active',
                    );
                }
            }
        };

        // Bind function to event.
        gallery.events.on('indexChanged', navSliderFunction);
    });
}

if (relatedProducts.length > 0) {
    relatedProducts.forEach((product, index) => {
        // Set default of first product
        let attribute;
        let newElement;

        if (index == 0) {
            attribute = `${product.dataset.current}`;
            newElement = document.createElement('span');
            newElement.innerHTML = attribute;
            if (relatedProductColour != null) {
                relatedProductColour.appendChild(newElement);
            }

            if (relatedProductBundle != null) {
                relatedProductBundle.appendChild(newElement);
            }
        }

        if (relatedProductBundle) {
            product.addEventListener(
                'click',
                e => {
                    e.preventDefault();
                    selectedProduct(e);
                    gallery.goTo(index);
                    navSlider.goTo(index);
                },
                { passive: false },
            );
        }

        function selectedProduct(event) {
            event.preventDefault();
            var elems = document.querySelector('.image-select--selected');
            if (elems !== null) {
                elems.classList.remove('image-select--selected');
            }
            event.target.parentNode.classList.add('image-select--selected');
        }

        product.addEventListener('mouseover', e => {
            e.preventDefault();
            // change related product attribute on hover
            attribute = `${product.dataset.attribute}`;
            newElement = document.createElement('span');
            newElement.innerHTML = attribute;

            if (relatedProductColour != null) {
                relatedProductColour.innerHTML = '';
                relatedProductColour.appendChild(newElement);
            }

            if (relatedProductBundle != null) {
                relatedProductBundle.innerHTML = '';
                relatedProductBundle.appendChild(newElement);
            }
        });

        product.addEventListener('mouseout', e => {
            e.preventDefault();
            // change related product attribute on hover
            attribute = `${product.dataset.current}`;
            newElement = document.createElement('span');
            newElement.innerHTML = attribute;

            if (relatedProductColour != null) {
                relatedProductColour.innerHTML = '';
                relatedProductColour.appendChild(newElement);
            }

            if (relatedProductBundle != null) {
                relatedProductBundle.innerHTML = '';
                relatedProductBundle.appendChild(newElement);
            }
        });
    });
}

if (maxPurchasableCheck) {
    const maxPurchasables = document.querySelectorAll(
        '[data-behaviour="max-purchasable"]',
    );

    const updateCart = document.querySelector('[data-behaviour="update-cart"]');
    const updateBasket = document.querySelector(
        '[data-behaviour="update-basket"]',
    );

    const submitButton = updateCart ? updateCart : updateBasket;

    console.log(submitButton);

    maxPurchasables.forEach(maxPurchasable => {
        let maxPurchasableAmt = maxPurchasable.dataset.maxPurchasable;
        let qtyContainer = maxPurchasable.nextElementSibling;

        let qty = qtyContainer.querySelector(
            '[data-behaviour="quantity-input"]',
        );

        if (qty) {
            qty.addEventListener('change', e => {
                if (parseInt(qty.value) > parseInt(maxPurchasableAmt)) {
                    maxPurchasable.dataset.maxPurchaseState = 'active';
                    submitButton.classList.add('button--disabled');
                    submitButton.setAttribute('disabled', true);
                } else {
                    delete maxPurchasable.dataset.maxPurchaseState;

                    //Check if any other instances of maxQtyError
                    const maxQtyError = document.querySelector(
                        '[data-max-purchase-state="active"]',
                    );

                    //Only remove if no other errors on the basket
                    if (!maxQtyError) {
                        submitButton.classList.remove('button--disabled');
                        submitButton.removeAttribute('disabled');
                    }
                }
            });
        }
    });
}
