import Utilities from './utilities';
const utilities = new Utilities();

const imgHeight = document.querySelectorAll(
    '[data-behaviour="get-img-height"]',
);
const getBlocks = document.querySelectorAll(
    '[data-behaviour="set-block-height"]',
);

const setBlockHeight = () => {
    if (imgHeight.length > 0) {
        imgHeight.forEach(img => {
            let clientHeight = img.clientHeight;
            const viewportWidth = document.documentElement.clientWidth;
            if (viewportWidth <= 500) {
                clientHeight = clientHeight + 60;
            } else {
                clientHeight = clientHeight + 90;
            }
            if (getBlocks.length > 0) {
                getBlocks.forEach(block => {
                    block.style.height = clientHeight + 'px';
                });
            }
        });
    }
};

window.addEventListener('load', setBlockHeight);
window.addEventListener(
    'resize',
    utilities.debounce(setBlockHeight, 200, false),
    false,
);
