window.addEventListener('addedToCart' || 'update_cart', listenForCartQuantity);
listenForCartQuantity();

function listenForCartQuantity() {
    const quantity = document.querySelectorAll('[data-behaviour="quantity"]');

    const updateCart = document.querySelector(
        '[data-behaviour="update-cart"]',
    );
    const updateBasket = document.querySelector(
        '[data-behaviour="update-basket"]',
    );

    let maxPurchasableCheck = document.querySelector(
        '[data-behaviour="max-purchasable"]',
    );

    const submitButton = updateCart ? updateCart : updateBasket;

    if (quantity.length) {
        quantity.forEach(el => {
            const changeLinks = el.querySelectorAll(
                '[data-behaviour="quantity-link"]',
            );
            const input = el.querySelector('[data-behaviour="quantity-input"]');

             //Catch if we're on the cart page and have multiple max purchase elements
            let maxPurchasable = el.previousElementSibling;
            maxPurchasable = maxPurchasable
                ? maxPurchasable
                : maxPurchasableCheck;

            changeLinks.forEach(changeLink => {
                changeLink.addEventListener(
                    'click',
                    e => {
                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation();

                        const action = e.target.getAttribute('data-action');
                        let quantityValue = Number(input.value);

                        quantityValue =
                            action === 'increase'
                                ? quantityValue + 1
                                : quantityValue - 1;

                        if (quantityValue < 0) {
                            quantityValue = 0;
                        }

                        input.value = quantityValue;

                        if (maxPurchasable) {
                            const maxPurchasableVal = maxPurchasable.dataset.maxPurchasable;
                            checkForMaxQuanity(
                                maxPurchasableVal,
                                quantityValue,
                                maxPurchasable,
                                submitButton,
                            );
                        }

                        submitButton.disabled = false;
                    },
                    { passive: false },
                );
            });
        });
    }
}

function checkForMaxQuanity(
    maxPurchasableVal,
    quantityValue,
    maxPurchasable,
    submitButton,
) {
    if (quantityValue > maxPurchasableVal) {
        maxPurchasable.dataset.maxPurchaseState = 'active';
        submitButton.classList.add('button--disabled');
    } else {
        delete maxPurchasable.dataset.maxPurchaseState;

        //Check if any other instances of maxQtyError
        const maxQtyError = document.querySelector(
            '[data-max-purchase-state="active"]',
        );

        //Only remove if no other errors on the basket
        if(!maxQtyError) {
             submitButton.classList.remove('button--disabled');
        }
    }
}
