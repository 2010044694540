// Inject YouTube API script

const videoId = document.querySelectorAll('[data-behaviour="data-video-id"]');
const playButton = document.querySelectorAll('[data-behaviour="video-play"]');
const watchTime = document.querySelector('[data-behaviour="video-length"]');
const videoPlayer = document.querySelector('[data-behaviour="video-player"]');
const videoContainer = document.querySelector(
    '[data-behaviour="video-container"]',
);
const contentContainer = document.querySelector(
    '[data-behaviour="content-container"]',
);

let player;

const tag = document.createElement('script');

tag.src = '//www.youtube.com/player_api';
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

window.onYouTubePlayerAPIReady = function() {
    player = new YT.Player('video', {
        videoId: videoId,
        playerVars: {
            modestBranding: 1,
            listType: 'user_uploads',
            list: 'PLfhRI67oEquInzUL1kSr0SPUq2o_gDOvK',
            controls: 0,
            disablekb: 1,
            origin: window.location.href,
            host: 'https://www.youtube.com',
            widget_referrer: window.location.href,
            enablejsapi: 1,
        },
        events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
        },
    });
};

function onPlayerReady() {
    playButton.forEach(play => {
        play.addEventListener('click', e => {
            e.stopPropagation();
            e.preventDefault();
            player.playVideo();
            if (watchTime != null) {
                watchTime.classList.add('watch-length--hide');
            }
            play.classList.add('video__play--hide');
            videoPlayer.classList.add('video__video--show');
            videoContainer.classList.add('content-with-video__video--expand');
            if (contentContainer != null) {
                contentContainer.classList.add(
                    'content-with-video__content--shrink',
                );
            }
        });
    });
}

// 5. The API calls this function when the player's state changes.
function onPlayerStateChange(event) {
    const playButton = document.querySelector('[data-behaviour="video-play"]');
    if (event.data == YT.PlayerState.PAUSED) {
        if (watchTime != null) {
            watchTime.classList.remove('watch-length--hide');
        }
        videoPlayer.classList.remove('video__video--show');
        videoContainer.classList.remove('content-with-video__video--expand');
        playButton.classList.remove('video__play--hide');
        if (contentContainer != null) {
            contentContainer.classList.remove(
                'content-with-video__content--shrink',
            );
        }
    }
}
