const openFilter = document.querySelectorAll('[data-behaviour="open-filter"]');
const filters = document.querySelector('.filter-bar');

if (openFilter.length > 0) {
    openFilter.forEach(filter => {
        filter.addEventListener('click', e => {
            e.preventDefault();
            filters.classList.toggle('active');
            filter.classList.toggle('active');
        });
    });
}

const filterItemToggles = document.querySelectorAll(
    '[data-behaviour="filter-list-toggle"]',
);

if (filterItemToggles.length > 0) {
    filterItemToggles.forEach(filterToggle => {
        filterToggle.addEventListener('click', e => {
            e.preventDefault();

            const classState = filterToggle
                .closest('[data-behaviour="filter-list"]')
                .classList.contains('filter-item--active');

            [].forEach.call(
                document.querySelectorAll(
                    '[data-behaviour="filter-list"].filter-item--active',
                ),
                el => {
                    el.closest(
                        '[data-behaviour="filter-list"]',
                    ).classList.remove('filter-item--active');
                },
            );

            if (!classState) {
                filterToggle
                    .closest('[data-behaviour="filter-list"]')
                    .classList.add('filter-item--active');
            }
        });
    });
}

const filterProducts = () => {
    const filterItems = document.querySelectorAll(
        '[data-behaviour="product-filter"]',
    );

    const applyFiltersBtn = document.querySelector(
        '[data-behaviour="apply-filters"]',
    );

    const clearFiltersLink = document.querySelector(
        '[data-behaviour="clear-filters"]',
    );

    if (!applyFiltersBtn) {
        return;
    }

    if (clearFiltersLink) {
        clearFiltersLink.addEventListener('click', e => {
            e.preventDefault();

            const url = new URL(window.location.href);

            // Remove all query params and any pagination
            url.pathname = url.pathname.replace(/page(\/[0-9]+\/)?/, '');
            url.search = '';
            const href = url.toString();
            window.location.href = href;
        });
    }

    filterItems.forEach(filterItem => {
        filterItem.addEventListener('change', e => {
            if (applyFiltersBtn.classList.contains('button--disabled')) {
                applyFiltersBtn.classList.remove('button--disabled');
            }

            // If the filter is checked and one of the per_page values
            // only allow one per_page value to be selected
            if (e.target.checked && e.target.dataset.filter === 'per_page') {
                const parentFilterList = filterItem.closest(
                    '[data-behaviour="filter-list"]',
                );

                parentFilterList
                    .querySelectorAll('[data-behaviour="product-filter"]')
                    .forEach(item => {
                        if (item.getAttribute('id') !== e.target.id) {
                            item.checked = false;
                        }
                    });
            }
        });
    });

    applyFiltersBtn.addEventListener('click', () => {
        const url = new URL(window.location.href);

        filterItems.forEach(filterItem => {
            const filterType = filterItem.dataset.filter;
            const filterParamValues = url.searchParams.get(filterType);

            if (
                url.searchParams.has(filterType) &&
                filterParamValues.length < 1
            ) {
                // If the current filter type exists in the url params,
                // but it has no values, delete it
                url.searchParams.delete(filterType);

                return;
            }

            // If the filter item is not checked and the filter type doesn't
            // exist in the url do nothing as it will be removed automatically
            if (!filterItem.checked && !url.searchParams.has(filterType)) {
                return;
            }

            // If the filter item is checked and the filter type doesn't exist
            // in the url add it with the first value
            if (filterItem.checked && !url.searchParams.has(filterType)) {
                url.searchParams.set(filterType, filterItem.value);

                return;
            }

            let filterParamArr = filterParamValues.split(',');
            let newfilterParamValue;

            // Only add value if it doesn't already existing in the filter
            // type params array
            if (
                filterItem.checked &&
                !filterParamArr.includes(filterItem.value)
            ) {
                filterParamArr.push(filterItem.value);
            }

            // If the current filter value exists for the given filter type and
            // is not checked, remove it
            if (
                !filterItem.checked &&
                filterParamArr.includes(filterItem.value)
            ) {
                const filterIdx = filterParamArr.indexOf(filterItem.value);

                filterParamArr.splice(filterIdx, 1);
            }

            // If the filter type is now empty, remove it from the url params
            if (filterParamArr.length === 0) {
                url.searchParams.delete(filterType);

                return;
            }

            newfilterParamValue = filterParamArr.join(',');

            url.searchParams.set(filterType, newfilterParamValue);
        });

        // Redirect on completion of filter param update, and always strip the
        // page param from the url to prevent 404 paging issues
        url.pathname = url.pathname.replace(/page(\/[0-9]+\/)?/, '');
        const href = url.toString();
        window.location.href = href;
    });

    const filterTags = document.querySelectorAll(
        '[data-behaviour="filter-tag"]',
    );

    if (filterTags.length > 0) {
        filterTags.forEach(tag => {
            tag.addEventListener('click', e => {
                e.preventDefault();

                const url = new URL(window.location.href);
                const filterType = tag.dataset.filter;
                const tagValue = tag.dataset.value;
                const filterParamValues = url.searchParams.get(filterType);

                if (
                    url.searchParams.has(filterType) &&
                    filterParamValues.length < 1
                ) {
                    // If the current filter type exists in the url params,
                    // but it has no values, delete it
                    url.searchParams.delete(filterType);

                    return;
                }

                let filterParamArr = filterParamValues.split(',');

                if (filterParamArr.includes(tagValue)) {
                    const filterIdx = filterParamArr.indexOf(tagValue);

                    filterParamArr.splice(filterIdx, 1);
                }

                // If the filter type is now empty remove it from the url params
                if (filterParamArr.length === 0) {
                    url.searchParams.delete(filterType);
                } else {
                    const newfilterParamValue = filterParamArr.join(',');

                    url.searchParams.set(filterType, newfilterParamValue);
                }

                url.pathname = url.pathname.replace(/page(\/[0-9]+\/)?/, '');
                const href = url.toString();
                window.location.href = href;
            });
        });
    }
};

filterProducts();
