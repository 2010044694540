const closeActions = document.querySelectorAll(
  '[data-behaviour="woocommerce-message-close"]'
);
closeActions.forEach((closeAction) => {
  closeAction.addEventListener('click', () => {
    const el = closeAction.parentElement;
    el.style.opacity = '0';
    setTimeout(() => el.remove(), 500);
  });
});
