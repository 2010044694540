import React from 'react';
import ReactRender from '../tools/react-render';
import BasketPopup from './BasketPopup';

const basketData = document.querySelector(
    '[data-behaviour="react-basket-popup"]',
);

if (basketData) {
    const attributesData = JSON.parse(basketData.getAttribute('data-basket'));
    ReactRender(<BasketPopup basketData={attributesData} />, basketData);
}
