import Utilities from './utilities';

const utilities = new Utilities();
let loading = false;

const addToCartButton = document.querySelector(
    '[data-behaviour="update-cart"]',
);

if (addToCartButton !== null) {
    addToCartButton.addEventListener('click', e => {
        e.preventDefault();

        if (addToCartButton.classList.contains('disabled')) return;
        if (loading) return;

        const buttonElement = addToCartButton;
        const formElement = buttonElement.closest('form.cart');
        const id = buttonElement.value;
        const product_qty =
            formElement.querySelector('input[name=quantity]').value || 1;
        const product_ids =
            formElement.querySelector('input[name=product_id]').value || id;
        const product_sku =
            formElement.querySelector('input[name=sku]').value || id;
        const variation_id =
            formElement.querySelector('input[name=variation_id]').value || id;

        const data = {
            action: 'ajaxAddToCart',
            product_sku: product_sku,
            product_ids,
            quantity: product_qty,
            variation_id: variation_id,
        };

        const queryString = utilities.param(data);

        buttonElement.classList.add('button--loading');
        loading = true;

        fetch(`${`${wp_ajax.ajax_url}?${queryString}`}`)
            .then(response => response.json())
            .then(result => {
                window.dispatchEvent(
                    new CustomEvent('addedToCart', {
                        detail: { disablePopup: false },
                    }),
                );
                buttonElement.classList.add('button--success');
                console.log(result);
                loading = false;

                setTimeout(() => {
                    buttonElement.classList.remove('button--loading');
                }, 3000);
            })
            .catch(error => {
                console.log(`Error during fetch: ${error}`);
            });

        return false;
    });
}
