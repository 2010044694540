const accordions = document.querySelectorAll('[data-behaviour="accordion"]');
if (accordions.length > 0) {
    accordions.forEach(accordion => {
        let header = accordion.querySelector(
            '[data-behaviour="accordion-header"]',
        );
        let body = accordion.querySelector('[data-behaviour="accordion-body"]');

        header.addEventListener('click', function(event) {
            event.preventDefault;

            header.classList.toggle('active');
            body.classList.toggle('active');
        });
    });
}
