function checkMaxLength(element) {
    const parent = element.closest('.form-row');

    if (!parent) return;

    const { length } = element.value;
    const maxlength = parseInt(element.getAttribute('maxlength'))

    if (parent.querySelectorAll('.form-row__error').length === 0) {
        const error = document.createElement('span');
        error.classList.add('form-row__error');
        error.innerHTML = `Max character limit is ${maxlength}`;
        parent.appendChild(error);
    }
    
    parent.classList[length === maxlength ? 'add' : 'remove']('form-row--max-cap');
}
document.addEventListener('DOMContentLoaded', () => [...document.querySelectorAll('input[maxlength]')].map(checkMaxLength));
document.addEventListener('input', ({target}) => target.matches('input[maxlength]') && checkMaxLength(target));
