const removeButton = document.querySelectorAll(
    '[data-behaviour="removed_from_cart"]',
);

removeButton.forEach(item => {
    item.addEventListener('click', e => {
        e.preventDefault();

        const product_id = item.dataset.product_id;

        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            data: {
                action: 'productRemove',
                product_id: product_id,
            },
            success: function(res) {
                if (res) {
                    window.dispatchEvent(
                        new CustomEvent('addedToCart', {
                            detail: { disablePopup: false },
                        }),
                    );
                }
            },
        });
    });
});
