let clickedField;
let isBilling;

function hideError() {
    const error = document.querySelector('[data-behaviour="postcoder-error-msg"]');

    if (error) error.closest('.woocommerce-notices-wrapper').classList.add('hidden');
}

function showError() {
    const error = document.querySelector('[data-behaviour="postcoder-error-msg"]');

    if (error) error.closest('.woocommerce-notices-wrapper').classList.remove('hidden');
}

function fieldError(error) {
    hideError();
    document.querySelector('[data-behaviour="postcoder-error-msg"]').innerHTML = error;
    showError();
}

function setFormFields(result) {
    const prefix = isBilling ? 'billing' : 'shipping';

    const address1 = document.getElementById(`${prefix}_address_1`);
    address1.value = result.addressline1;
    address1.removeAttribute('placeholder');
    
    const address2 = document.getElementById(`${prefix}_address_2`);
    address2.value = result.addressline2 ?? '';
    address2.removeAttribute('placeholder');
    
    const city = document.getElementById(`${prefix}_city`);
    city.value = result.posttown;
    city.removeAttribute('placeholder');
    
    const county = document.getElementById(`${prefix}_state`);
    county.value = result.county;
    county.removeAttribute('placeholder');
    
    const postcode = document.getElementById(`${prefix}_postcode`);
    postcode.value = result.postcode;
    postcode.removeAttribute('placeholder');
}

async function searchAddress(country = 'GB', address) {
    const key = 'PCWFD-SMVSL-H8JDC-FBTN7';
    const url = `https://ws.postcoder.com/pcw/${key}/address/${country}/${address}?lines=2`;

    const response = await fetch(url);

    if (!response.ok) {
        const error = await response.text();
        throw new Error(`${response.status}: ${error}`);
    }

    return await response.json();
}

function postcoder(selector) {
    const postcodeRegex = /[A-Z]{2}\d[A-Z]{1} \d[A-Z]{2}|[A-Z]{1}\d[A-Z]{1} \d[A-Z]{2}|[A-Z]{1}\d \d[A-Z]{2}|[A-Z]{1}\d\d \d[A-Z]{2}|[A-Z]{2}\d \d[A-Z]{2}|[A-Z]{2}\d\d \d[A-Z]{2}/;
    document.addEventListener('change', e => {
        if (!e.target.matches('.form-row--address-finder input')) return;
        const {value} = e.target;
        const isUK = document.querySelector(`[name=${e.target.name.split('_')[0]}_country]`).value === 'GB';
        if (!isUK) return;
        if (postcodeRegex.test(value.trim())) return;

        let newValue = `${value}`;

        switch(value.trim().length) {
            case 5: newValue = value.slice(0,2) + ' ' + value.slice(3); break;
            case 6: newValue = value.slice(0,3) + ' ' + value.slice(3); break;
            case 7: newValue = value.slice(0,4) + ' ' + value.slice(4); break;
        }

        e.target.value = newValue;
    });
    document.addEventListener('click', e => {
        if (!e.target.matches(selector)) return;

        e.preventDefault();

        const postcode = document.querySelector(e.target.dataset.postcodeField);

        isBilling = e.target.dataset.addressType === "billing";
        clickedField = e.target;

        if (!postcode) return fieldError('We cannot lookup the address from the postcode. Please enter your address manually.');
        if (!postcode.value) return fieldError('A postcode is required.');

        
        const country = document.querySelector(`[name=${isBilling ? 'billing' : 'shipping'}_country]`).value;

        searchAddress(country, encodeURIComponent(postcode.value))
            .then((results) => {
                if (results.length === 0) return fieldError('No addresses found.');
                if (results.length === 1) return setFormFields(results[0]);

                let options = results
                    .map((result, index) => `<option value="${index}">${result.summaryline}</option>`)
                    .join('');

                const selectId = isBilling ? '#billing-dropdown' : '#shipping-dropdown';
                const dropdown = document.querySelector(selectId);
                
                hideError();

                // ew jQuery
                if (dropdown.matches('.select2-hidden-accessible')) jQuery(dropdown).select2('destroy');
                dropdown.dataset;
                jQuery(dropdown).select2({
                    // dropdownParent: $(selectId).closest('.form-row--address-finder')
                    dropdownPosition: 'below'
                });
                jQuery(dropdown).on('select2:select', e => {
                    const result = results[e.target.value];
                    setFormFields(result);
                })
                const defaultText = `${results.length} addresses found...`;

                options = `<option>${defaultText}</option>` + options;

                dropdown.innerHTML = options;
                dropdown.closest('.form-row').classList.remove('hidden');
            })
            .catch((error) => fieldError('An error occured, please try again later.'));
    });
}

// document.addEventListener('change', (e) => {
//     if (e.target.matches(`#${selectId}`)) return;

//     const result = results[e.target.value];
//     setFormFields(result);
// });

export default postcoder;