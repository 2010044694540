const registerForm = document.querySelector(
    '[data-behaviour="register-validate"]',
);
const registerFormButton = document.querySelector(
    '[data-behaviour="register-form-button"]',
);

let formValidated = false;

if (registerForm != null) {
    let formFields = [];
    const firstName = registerForm.querySelector(
            'input[name="billing_first_name"]',
        ),
        lastName = registerForm.querySelector(
            'input[name="billing_last_name"]',
        ),
        email = registerForm.querySelector('input[name="email"]'),
        password = registerForm.querySelector('input[name="password"]'),
        datapolicy = registerForm.querySelector('input[name="data-policy"]');
    formFields = [firstName, lastName, email, password, datapolicy];

    formFields.forEach(field => {
        if (field)
            field.addEventListener('input', () => {
                field.setCustomValidity('');
                field.checkValidity();

                if (field) {
                    formValidated = true;
                }
            });

        field.addEventListener('invalid', () => {
            field.setCustomValidity('Please fill in this field.');
        });
    });

    if (formValidated && formFields.datapolicy.checked) {
        registerFormButton.removeAttribute('disabled');
    } else {
        registerFormButton.setAttribute('disabled', true);
    }
}
