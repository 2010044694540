const tabs = document.querySelectorAll('[data-behaviour="tabs"]');
const anchorItems = document.querySelectorAll(
    '[data-bevaviour="anchor-nav-item"]',
);

const dropSelector = document.querySelectorAll(
    '[data-behaviour="dropdown-select"]',
);

if (anchorItems.length > 0) {
    anchorItems.forEach(item => {
        item.addEventListener('click', e => {
            let active = document.querySelectorAll('.anchor-nav-item.active');
            for (let i = 0; i < active.length; i++) {
                active[i].classList.remove('active');
            }

            e.target.classList.add('active');
        });
    });
}

if (tabs.length > 0) {
    tabs.forEach(tab => {
        let controls = tab.querySelectorAll('[data-behaviour="tab-target"]');
        let results = tab.querySelectorAll('[data-behaviour="tab-content"]');

        if (controls.length > 0 && results.length > 0) {
            controls.forEach(controls => {
                controls.addEventListener(
                    'click',
                    function(event) {
                        event.preventDefault
                            ? event.preventDefault()
                            : (event.returnValue = false);

                        let control = document.querySelectorAll(
                            '.tabs__header li.active',
                        );

                        for (let i = 0; i < control.length; i++) {
                            control[i].classList.remove('active');
                        }

                        let result = document.querySelectorAll(
                            '.tabs__content.active',
                        );

                        for (let i = 0; i < result.length; i++) {
                            result[i].classList.remove('active');
                        }

                        this.classList.add('active');

                        if (this.dataset.hasOwnProperty('urlParams')) {
                            // Update url
                            const searchParams = new URLSearchParams(
                                window.location.search,
                            );
                            const type = this.dataset.target;
                            const currentType = searchParams.get('type');

                            let path = window.location.pathname;

                            if (currentType !== type) {
                                path = path.includes('page') ? '' : path;
                            }

                            searchParams.set('type', type);

                            const params = searchParams.toString();
                            const urlQuery = `${path}?${params}`;

                            if (path === '') {
                                window.location.href = `${window.location.origin}${urlQuery}`;
                            }

                            history.pushState(null, '', urlQuery);

                            // Update pagination links
                            const tabTarget = tab.querySelector(
                                `#${this.dataset.target}`,
                            );
                            const paginationLinks = tabTarget.querySelectorAll(
                                '[data-behaviour="pagination-link"]',
                            );

                            if (paginationLinks.length > 0) {
                                paginationLinks.forEach(link => {
                                    let linkHref = new URL(
                                        link.getAttribute('href'),
                                    );
                                    const linkParams = new URLSearchParams(
                                        linkHref.search,
                                    );
                                    linkParams.set('type', type);
                                    link.setAttribute(
                                        'href',
                                        `${linkHref.origin}${
                                            linkHref.pathname
                                        }?${linkParams.toString()}`,
                                    );
                                });
                            }
                        }

                        tab.querySelector(
                            '#' + this.dataset.target,
                        ).classList.add('active');
                    },
                    { passive: false },
                );
            });

            let activeControl = controls[0];
            let activeResults = results[0];

            // Check if a type param has been set in the url
            const searchParams = new URLSearchParams(window.location.search);
            const currentType = searchParams.get('type');

            if (currentType) {
                activeControl = tab.querySelector(
                    `[data-target="${currentType}"]`,
                );
                activeResults = tab.querySelector(`#${currentType}`);
            }

            activeControl.classList.add('active');
            activeResults.classList.add('active');

            if (dropSelector.length > 0) {
                dropSelector.forEach(select => {
                    select.addEventListener('change', event => {
                        let selectResult = select.value;
                        let result = document.querySelectorAll(
                            '.tabs__content.active',
                        );

                        for (let i = 0; i < result.length; i++) {
                            result[i].classList.remove('active');
                        }

                        tab.querySelector('#' + selectResult).classList.add(
                            'active',
                        );
                    });
                });
            }
        }
    });
}
