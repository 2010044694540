const siteSwitcher = document.querySelectorAll(
    '[data-behaviour="site-switcher"]',
);
const siteOptions = document.querySelector(
    '[data-behaviour="site-switcher-options"]',
);
const burgerMenu = document.querySelector('[data-behaviour="burger-menu"]');
const openSearch = document.querySelector('[data-behaviour="open-search"]');
const searchBar = document.querySelector('[data-behaviour="search-bar"]');
const siteContent = document.querySelector('[data-behaviour="site-content"]');
const siteNavigation = document.querySelector(
    '[data-behaviour="site-navigation"]',
);
const navigationMenu = document.querySelector(
    '[data-behaviour="navigation-menu"]',
);
const openChildMenus = document.querySelectorAll(
    '[data-behaviour="child-menu-toggle"]',
);
const body = document.querySelector('body');
const html = document.querySelector('html');

if (siteSwitcher.length > 0) {
    siteSwitcher.forEach(item => {
        item.addEventListener('click', e => {
            e.preventDefault();
            item.classList.toggle('is-active');
            item.parentElement.classList.toggle('is-active');
            siteOptions.classList.toggle('is-active');
        });
    });
}

if (burgerMenu != null) {
    burgerMenu.addEventListener('click', e => {
        e.preventDefault();
        burgerMenu.classList.toggle('active');
        siteNavigation.classList.toggle('active');
        body.classList.toggle('locked');
        html.classList.toggle('locked');
    });
}

if (openChildMenus.length > 0) {
    openChildMenus.forEach(openChildMenu => {
        openChildMenu.addEventListener('click', e => {
            e.preventDefault();
            const currentMenu = e.currentTarget;
            currentMenu.classList.toggle('active');

            navigationMenu.classList.toggle('active');
            currentMenu.classList.toggle('active');
            currentMenu.parentNode.classList.toggle('active');
        });
    });
}

if (openSearch != null) {
    openSearch.addEventListener('click', e => {
        e.preventDefault();
        searchBar.classList.toggle('is-active');
        siteContent.classList.toggle('site-content__overlay');
        openSearch.classList.toggle('is-active');
        body.classList.toggle('locked');
        html.classList.toggle('locked');
    });
}
