const body = document.querySelector('body');
const html = document.querySelector('html');

$(window).on('searchwp_live_search_start', () => {
    body.classList.add('locked');
    html.classList.add('locked');
});

$(window).on('searchwp_live_destroy_results', () => {
    body.classList.remove('locked');
    html.classList.remove('locked');
});

// on input toggle the clear button
document.addEventListener('input', e => {
    if (!e.target.matches('[data-behaviour="search-input"]')) return;

    const isEmpty = e.target.value == '';
    const searchBar = e.target.closest('.search-bar');

    searchBar.classList[isEmpty ? 'remove' : 'add']('search-bar--show-clear');
});

// on clicking the clear button clear the input field for the search within the same form
document.addEventListener('click', e => {
    if (!e.target.matches('[data-behaviour="search-clear"]')) return;

    e.preventDefault();

    const searchBar = e.target.closest('.search-bar');
    const input = searchBar.querySelector('[data-behaviour="search-input"]');

    input.value = '';
});
