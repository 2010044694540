const readMoreTrigger = document.querySelectorAll([
    '[data-behaviour="read-more-trigger"]',
]);
const readMoreContainer = document.querySelector([
    '[data-behaviour="read-more-container"]',
]);

if (readMoreTrigger.length > 0) {
    readMoreTrigger.forEach(element => {
        currentText = element.innerText;
        element.addEventListener('click', () => {
            readMoreContainer.classList.toggle('u-read-more-hidden');
            element.classList.toggle('no-grad');

            if (element.innerText == currentText) {
                element.innerText = 'Read less';
            } else {
                element.innerText = currentText;
            }
        });
    });
}
